.socialrow {
  background-color: rgba($color: #fff, $alpha: 0.2);
  border: 1px solid #4d96d5;
  text-decoration: none;
  margin-bottom: 5px;
  transition: all 0.2s;
  border-radius: 20px;
  &:hover {
    transform: scale(0.99);
  }
  .row {
    padding: 10px;
    img {
      width: 80%;
      margin: 0 auto;
    }
    div {
      span {
        position: relative;
        font-size: 40px;
        text-decoration: none;
        text-shadow: #4d96d5 0px 0px 10px;
      }
    }
  }
}
