#mainmenu {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: #222;
  z-index: 9999;
  .menu-toggler {
    display: none;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    top: 0;
    right: 0;
    font-size: 30px;
    @media (max-width: 768px) {
      display: block;
    }
  }
  ul.menulinks {
    display: flex;
    @media (max-width: 768px) {
      display: none;
    }
    &.active {
      display: block;
    }
    li.link {
      list-style: none;
      padding: 10px;
      margin-right: 10px;
      .mlink {
        display: inline-block;
        color: #fff;
        text-decoration: none;
        &:hover {
          background-color: #333;
        }
      }
    }
  }
}
