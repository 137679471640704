@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible&display=swap");

body {
  background-color: #2f2f2f;
  * {
    color: #fff;
    font-family: "Atkinson Hyperlegible", sans-serif;
  }
  p{
    font-family: "Atkinson Hyperlegible", sans-serif;
    a{
        color: #6495ed;
        margin-left: 5px;
    }
  }
  h1 {
    color: #6495ed;
    font-family: "MedievalSharp", cursive;
  }
}
