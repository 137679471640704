.lso {
  img {
    width: 100%;
    height: auto;
  }
  .imgcover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
