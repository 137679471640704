#mainfooter {
  position: absolute;
  background-color: #1c1c1c;
  bottom: 0;
  width: 100vw;
  padding: 20px 0;
  .sociallinks {
    .sociallink {
      img {
        width: 30px;
        margin-right: 10px;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
