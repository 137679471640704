@import url("https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible&display=swap");

#socialLinks {
  max-width: 500px;
  border: 1px solid #0095eb;
  padding: 20px;
  display: block;
  box-shadow: 0px 0px 10px #0095eb;
  * {
    font-family: "Atkinson Hyperlegible", sans-serif;
    text-align: center;
  }
  .logo {
    width: 300px;
    position: relative;
    margin: 0 auto;
  }
}
