#mainPage {
  /* border: 1px solid black; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .lso-c {
    display: flex;
    justify-content: center;
    align-items: center;
    #lsomain {
      width: 350px;
    }
  }
}

#about {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 80%;
  height: 80%;
  z-index: 99991;
  background-color: #000;
  border: 5px solid #fff;

  &.active {
    display: block;
  }

  .closeAbout {
    position: absolute;
    z-index: 1;
    top: -50px;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    font-size: 50px;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .info {
    z-index: 2;
    width: 100%;
    height: 95%;
    overflow: hidden;
    overflow-y: auto;
  }
}
